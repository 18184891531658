.loader {
  justify-content: center;
  width: 100vw;
  height: 100vh;
  display: flex;
}

.fond {
  margin: auto;
}

.container_general {
  -ms-animation: animball_two 1s infinite;
  width: 44px;
  height: 44px;
  animation: 1s infinite animball_two;
}

.container_mixt {
  width: 44px;
  height: 44px;
  position: absolute;
}

.ballcolor {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.ball_1, .ball_2, .ball_3, .ball_4 {
  -ms-animation: animball_one 1s infinite ease;
  animation: 1s infinite animball_one;
  position: absolute;
}

.ball_1 {
  background-color: #cb2025;
  top: 0;
  left: 0;
}

.ball_2 {
  background-color: #f8b334;
  top: 0;
  left: 24px;
}

.ball_3 {
  background-color: #00a096;
  top: 24px;
  left: 0;
}

.ball_4 {
  background-color: #97bf0d;
  top: 24px;
  left: 24px;
}

@keyframes animball_one {
  0% {
    position: absolute;
  }

  50% {
    opacity: .5;
    position: absolute;
    top: 12px;
    left: 12px;
  }

  100% {
    position: absolute;
  }
}

@-webkit-keyframes animball_two {
  0% {
    -webkit-transform: rotate(0)scale(1);
  }

  50% {
    -webkit-transform: rotate(360deg)scale(1.3);
  }

  100% {
    -webkit-transform: rotate(720deg)scale(1);
  }
}

@-moz-keyframes animball_two {
  0% {
    -moz-transform: rotate(0)scale(1);
  }

  50% {
    -moz-transform: rotate(360deg)scale(1.3);
  }

  100% {
    -moz-transform: rotate(720deg)scale(1);
  }
}

@keyframes animball_two {
  0% {
    transform: rotate(0)scale(1);
  }

  50% {
    transform: rotate(360deg)scale(1.3);
  }

  100% {
    transform: rotate(720deg)scale(1);
  }
}
/*# sourceMappingURL=index.2e0d1852.css.map */
